.icons-wrap i {
    font-size: 20px;
    color: #666;
    cursor: pointer;
}

.icons-wrap i:hover {
    color: #0ac074
}

/*tbody, td, tfoot, th, thead, tr:hover { box-shadow:2px 10px 15px #666; cursor:pointer; }*/
.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
    vertical-align: middle;
    height: 66px;
}

.flex {
    display: flex;
    column-gap: 5px
}

.icons-wrap {
    vertical-align: middle;
}

.count-font {
    font-size: 20px
}

.filter-list {
    gap: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
}

.filter-list li {
    list-style: none
}

.custum-width {
    width: 250px;
    padding: 10px
}


.custum-width .dropdown-item {
    padding: 0.35rem 0;
}

.filter-wrap .app-search {
    padding: 0 !important;
}

.fl-r {
    float: right;
}

.w-100 {
    width: 100%
}

.app-search.client-search {
    padding: 0
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}


.profile-image {
    display: flex;
    align-items: center;
    gap: 20px;
}

.profile-image img {
    width: 100px
}

.w-50 {
    width: 50%
}


/*.app-search.client-search .form-control { background: #ffffff !important;  }*/



body[data-sidebar=dark] .navbar-brand-box {
    background: #f3f3f4 !important;
}

body[data-sidebar=dark] .vertical-menu {
    background: #123258 !important;
}

.bg-pattern {
    background-image: url(../images/bg.jpg);
    background-size: cover;
    background-position: center;
    height: 100vh;
    margin: 0;
    padding: 0;
    position: fixed;
    width: 100%;
}

.lds-ellipsis {
    display: inline-block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 80px;
    z-index: 9999999999999999999999999999999999999 !important;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    z-index: 9999999999999999999999999999999999999 !important;
    height: 13px;
    border-radius: 50%;
    background: #F5774B;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    z-index: 9999999999999999999999999999999999999 !important;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    z-index: 9999999999999999999999999999999999999 !important;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    z-index: 9999999999999999999999999999999999999 !important;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    z-index: 9999999999999999999999999999999999999 !important;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

.loader {
    position: absolute;
    height: -webkit-fill-available;
    background-color: rgba(123, 129, 125, 0.278);
    width: 100%;
    z-index: 99999 !important;
}

.toast {
    width: 350px;
    max-width: 100%;
    font-size: .875rem;
    pointer-events: auto;
    /* background-color: rgba(255,255,255,.85); */
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 1px 1px rgb(0 0 0 / 8%);
    border-radius: 0.25rem;
    position: fixed;
    top: 10px;
    right: 10px;
    color: white;
    z-index: 99999999999999999999999;
}

.success {
    background-color: #0ac074;
    z-index: 9999;
}

.failure {
    background-color: #ec5045;
    z-index: 9999;
}

.toast-header .btn-close {
    font-size: 10px;
    position: absolute;
    right: 10px;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    float: right;
    margin-top: 10px;
}

.table {
    text-align: center;
}

.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #ff3d60;
}

.float-right {
    float: right;
}

.cursor-pointer {
    cursor: pointer;
}

.mr-1 {
    margin-right: 1em;
}

.ml-1 {
    margin-left: 1em;
}

.up-arrow:after {
    display: inline-block;
    content: " ";
    margin-left: 4px;
    margin-bottom: 4px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
}

.down-arrow:after {
    display: inline-block;
    content: " ";
    margin-left: 4px;
    margin-bottom: 4px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
}

.filter-wrapper {
    position: absolute;
    width: 500px;
    background-color: white;
}

.blue-color {
    color: #03a9f4
}

.remove-btn-stl {
    border: none;
    background-color: #f8f9fa;
}

.mw-100 {
    min-width: 100px;
}

.mw-150 {
    min-width: 150px;
}

.mw-200 {
    min-width: 200px;
}

.mw-300 {
    min-width: 300px;
}

@media screen and (max-width: 480px) {
    .mw-110 {
        min-width: 110px;
    }
}

@media screen and (min-width: 480px) {
    .mw-110 {
        min-width: 80px;
    }
}

.mw-400 {
    min-width: 400px;
}

.mw-500 {
    min-width: 500px;
}

.mw-600 {
    min-width: 600px;
}

.wf {
    width: 48%;
    float: left;
    margin-right: 2%;
}

.ws {
    width: 47%;
    float: left;
}


/* .ant-popover-inner {
    margin-top: 100px;
} */

.duplicateIcons img {
    width: 30px;
    height: auto;
    cursor: pointer;
}

.pointer-events-none {
    pointer-events: noen;
}